<template>
  <div class="vuse-content-wrapper user-exam">
    <smart-breadcrumbs :items="navigationElements" />

    <v-card color="transparent" flat>
      <v-toolbar dense class="" color="primary">
        <v-toolbar-title class="text-h6">
          {{ exam.course ? ' ' + exam.course.name : '' }}
        </v-toolbar-title>
      </v-toolbar>
      <v-row class="mx-0">
        <v-col cols="12" md="8">
          <v-card class="neu-glow mb-4">
            <v-card-title>
              {{ currentQuestion.text }}
            </v-card-title>
            <v-card-text>
              <v-radio-group
                v-model="selectedAnswer"
                @change="setAnswer($event, currentQuestion.id)"
              >
                <v-radio
                  v-for="answer in currentQuestion.answers"
                  :key="answer.id"
                  :label="answer.text"
                  :value="answer.id + '|' + answer.valid"
                />
              </v-radio-group>
            </v-card-text>
          </v-card>
          <v-row class="mx-0 my-4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  dark
                  class="mb-2"
                  style="float: right"
                  @click.prevent="handleChangeQuestion(-1)"
                  >Anterior
                </v-btn>
              </template>
              <span>Pregunta anterior</span>
            </v-tooltip>
            <v-spacer />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  dark
                  class="mb-2"
                  style="float: right"
                  @click.prevent="handleChangeQuestion(1)"
                  >Siguiente
                </v-btn>
              </template>
              <span>Siguiente pregunta</span>
            </v-tooltip>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <v-card class="neu-glow">
            <v-card-text>
              <p class="text-h5 text--primary">
                Pregunta {{ currentQuestionIndex + 1 }}/{{
                  exam.questions ? exam.questions.length : 0
                }}
              </p>
              <p>Respuestas correctas requeridas: {{ requiredAssertions }}</p>
              <div>Tiempo restante</div>
              <v-row class="mx-0">
                <vue-countdown-timer
                  @start_callback="
                    ''

                  "
                  @end_callback="saveUserAnswers()"
                  :start-time="startAt"
                  :end-time="endAt"
                  :interval="1000"
                  :start-label="''"
                  :end-label="''"
                  label-position="begin"
                  :end-text="'Examen finalizado'"
                  :day-txt="'días'"
                  :hour-txt="'horas'"
                  :minutes-txt="'minutos'"
                  :seconds-txt="'segundos'"
                  :show-zero="false"
                />
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="green darken-1"
                    dark
                    block
                    @click.prevent="saveUserAnswers()"
                  >
                    Finalizar
                  </v-btn> </template
                ><span>Enviar respuestas</span></v-tooltip
              >
              <v-dialog v-model="dialog" :width="dialogWidth">
                <v-card>
                  <v-card-title
                    v-if="aproved"
                    class="text-subtitle-1 text-center grey lighten-2 justify-center"
                    primary-title
                    style="word-break: break-word"
                  >
                    ¡Felicidades! <br />
                    Acabas de aprobar el examen de {{ exam.course.name }}
                  </v-card-title>
                  <v-card-title
                    v-else
                    class="text-body-1 grey lighten-2 mx-auto justify-center"
                    primary-title
                  >
                    ¡No te rindas! <br />
                    No cumpliste con el requisito de {{ requiredAssertions }} respuestas
                    correctas. Inténtalo nuevamente :)'
                  </v-card-title>

                  <!-- <v-card-text class="text-center">
                    <img
                      width="50%"
                      src="https://cdn4.iconfinder.com/data/icons/award-and-trophy-flat-0619/64/trophy-ribbon-reward-ribbon-512.png"
                    />
                  </v-card-text> -->

                  <v-data-table
                    :headers="headers"
                    :items="userAnswers"
                    item-key="id"
                    class="elevation-1"
                    hide-default-footer
                  >
                    <template v-slot:[`item.data.answer`]="{ item }">
                      <span
                        :class="[
                          item.data.answer.valid
                            ? 'response__border--aproved-color'
                            : 'response__border--failed-color',
                          'response-cell',
                        ]"
                      >
                        {{ item.data.answer.text }}
                      </span>
                    </template>
                  </v-data-table>
                  <v-divider />

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      text
                      @click="$router.push({ name: 'business/elearning/UserCourses' })"
                    >
                      Aceptar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
  import { decryptData } from '@/utils/encryption'
  export default {
    name: 'UserExam',
    data() {
      return {
        aproved: null,
        dialog: false,
        startAt: '',
        endAt: '',
        requiredAssertionsPercent: 80,
        exam: {},
        questionsCount: 3,
        examUserId: 0,
        currentQuestionIndex: 0,
        selectedAnswer: null,
        userAnswers: this.$session.get('examUserAnswers')
          ? this.$session.get('examUserAnswers')
          : [],
        headers: [
          { text: 'Pregunta', value: 'data.question.text' },
          { text: 'Tu respuesta', value: 'data.answer' },
        ],
        navigationElements: [
          {
            text: 'Mis Cursos',
            disabled: false,
            query: null,
            params: null,
            name: 'business/elearning/UserCourses',
            href: '/business/elearning/user-panel/courses',
          },
          {
            text: 'Detalles del curso',
            disabled: false,
            query: null,
            params: { courseId: 0 },
            name: 'business/elearning/UserCourseDetail',
            href: '/business/elearning/user-panel/courses',
          },
          {
            text: 'Exámen del curso',
            disabled: true,
            href: '/business/elearning/user-panel/lessons',
            name: '',
          },
        ],
      }
    },
    watch: {
      selectedAnswer(newValue) {
        this.$session.set('examUserAnswers', this.newValue)
      },
      currentQuestion(newValue) {
        this.getSelected(newValue.id)
      },
    },
    created() {
      this.getExam()
    },
    mounted() {},
    computed: {
      dialogWidth() {
        return this.$vuetify.breakpoint.smAndDown ? '95%' : '70%'
      },
      currentQuestion() {
        if (this.exam.questions && this.exam.questions.length > 0) {
          this.getSelected(this.exam.questions[this.currentQuestionIndex].id)
          return this.exam.questions[this.currentQuestionIndex]
        } else {
          return {}
        }
      },
      requiredAssertions() {
        return Math.ceil((this.questionsCount * this.requiredAssertionsPercent) / 100)
      },
    },
    methods: {
      decryptData: decryptData,
      getSelected(questionId) {
        const recordedAnswer = this.userAnswers.find(
          (item) => item.question_id === questionId
        )
        if (recordedAnswer) {
          this.selectedAnswer = recordedAnswer.answer_id + '|' + recordedAnswer.valid
        } else {
          this.selectedAnswer = null
        }
      },
      verifyExamStart() {
        if (!this.$session.get('examStarted')) {
          this.startAt = new Date()
          this.endAt = new Date(new Date().getTime() + this.exam.duration * 60000)
          this.$session.set('examStarted', {
            exam: this.exam,
            startAt: this.startAt,
            endAt: this.endAt,
          })
        } else {
          this.startAt = new Date(this.$session.get('examStarted').startAt)
          this.endAt = new Date(this.$session.get('examStarted').endAt)
        }
        this.createUserExam()
      },
      getExam() {
        this.$axios.get('exams/' + this.$route.params.examId).then((response) => {
          this.exam = response.data
          this.navigationElements[1].params = { courseId: this.exam.course_id }
          this.verifyExamStart()
          this.questionsCount = this.exam.questions.length
        })
      },
      handleChangeQuestion(int) {
        if (int > 0) {
          if (this.currentQuestionIndex < this.questionsCount - 1) {
            this.currentQuestionIndex++
          }
        } else {
          if (this.currentQuestionIndex > 0) {
            this.currentQuestionIndex--
          }
        }
      },
      createUserExam() {
        const time = new Date(this.endAt)
        const timeFormat = new Date(
          time.getTime() - time.getTimezoneOffset() * 60000
        ).toISOString()
        this.$axios
          .post('exam-users', {
            exam_id: this.exam.id,
            user_id: decryptData(this.$session.get('userId')),
            expired_at:
              timeFormat.split('T')[0] + ' ' + timeFormat.split('T')[1].split('.')[0],
          })
          .then((response) => {
            this.examUserId = response.data.examUser.id
          })
      },
      setAnswer(answerData, questionId) {
        if (answerData) {
          const answerId = parseInt(answerData.split('|')[0])
          this.userAnswers = this.userAnswers.filter(
            (item) => item.question_id !== questionId
          )
          this.userAnswers.push({
            answer_id: answerId,
            question_id: questionId,
            exam_user_id: this.examUserId,
            valid: answerData.split('|')[1],
            data: {
              question: this.exam.questions.find((item) => item.id === questionId),
              answer: this.exam.questions
                .find((item) => item.id === questionId)
                .answers.find((item) => item.id === answerId),
            },
          })
          this.$session.set('examUserAnswers', this.userAnswers)
        }
      },
      saveUserAnswers() {
        if (this.examUserId) {
          this.$axios
            .patch('exam-users/' + this.examUserId, {
              userAnswers: this.userAnswers,
            })
            .then(() => {})
            .finally(() => {
              this.verifyExamAproved()
            })
          this.verifyExamAproved()
        }
      },
      verifyExamAproved() {
        let assertions = 0
        let assertionsPercent = 0
        this.userAnswers.forEach((item) => {
          if (item.valid) {
            assertions++
          }
        })
        assertionsPercent = Math.ceil((assertions / this.exam.questions.length) * 100)
        if (assertionsPercent >= this.requiredAssertionsPercent) {
          this.saveApprovedCourse()
          this.aproved = true
          // this.$swal({
          //   title: '¡Felicidades!',
          //   text: 'Acabas de aprobar el examen de ' + this.exam.course.name,
          //   icon: 'success',
          //   showCancelButton: false,
          //   confirmButtonColor: 'primary',
          //   confirmButtonText: 'Aceptar',
          // })
        } else {
          this.aproved = false
          // this.$swal({
          //   title: 'No te rindas',
          //   text:
          //     'No cumpliste con el requisito de ' +
          //     this.requiredAssertions +
          //     ' respuestas correctas. Inténtalo nuevamente :)',
          //   icon: 'info',
          //   showCancelButton: false,
          //   confirmButtonColor: 'primary',
          //   confirmButtonText: 'Aceptar',
          // })
        }
        this.dialog = true
      },
      saveApprovedCourse() {
        const time = new Date()
        const timeFormat = new Date(
          time.getTime() - time.getTimezoneOffset() * 60000
        ).toISOString()
        this.$axios.patch('courses/' + this.exam.course.id, {
          courseUser: [
            {
              user_id: decryptData(this.$session.get('userId')),
              points: this.exam.points + this.exam.course.points,
              exam_approved: 1,
              date:
                timeFormat.split('T')[0] + ' ' + timeFormat.split('T')[1].split('.')[0],
            },
          ],
        })
      },
    },
    beforeDestroy() {
      this.$session.remove('examStarted')
      this.$session.remove('examUserAnswers')
    },
  }
</script>
<style lang="scss">
  .user-exam {
    .v-breadcrumbs {
      padding: 5px 10px !important;
    }
    .v-breadcrumbs__item {
      font-size: 12px;
    }
  }
  .response-cell {
    display: block;
    border-radius: 4px;
    padding: 4px;
    margin: 4px;
  }
  .response__border--aproved-color {
    border: 1px solid green;
  }
  .response__border--failed-color {
    border: 1px solid red;
  }
</style>
