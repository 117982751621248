var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vuse-content-wrapper user-exam"},[_c('smart-breadcrumbs',{attrs:{"items":_vm.navigationElements}}),_c('v-card',{attrs:{"color":"transparent","flat":""}},[_c('v-toolbar',{attrs:{"dense":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.exam.course ? ' ' + _vm.exam.course.name : '')+" ")])],1),_c('v-row',{staticClass:"mx-0"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{staticClass:"neu-glow mb-4"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.currentQuestion.text)+" ")]),_c('v-card-text',[_c('v-radio-group',{on:{"change":function($event){return _vm.setAnswer($event, _vm.currentQuestion.id)}},model:{value:(_vm.selectedAnswer),callback:function ($$v) {_vm.selectedAnswer=$$v},expression:"selectedAnswer"}},_vm._l((_vm.currentQuestion.answers),function(answer){return _c('v-radio',{key:answer.id,attrs:{"label":answer.text,"value":answer.id + '|' + answer.valid}})}),1)],1)],1),_c('v-row',{staticClass:"mx-0 my-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",staticStyle:{"float":"right"},attrs:{"text":"","color":"primary","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.handleChangeQuestion(-1)}}},'v-btn',attrs,false),on),[_vm._v("Anterior ")])]}}])},[_c('span',[_vm._v("Pregunta anterior")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",staticStyle:{"float":"right"},attrs:{"text":"","color":"primary","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.handleChangeQuestion(1)}}},'v-btn',attrs,false),on),[_vm._v("Siguiente ")])]}}])},[_c('span',[_vm._v("Siguiente pregunta")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"neu-glow"},[_c('v-card-text',[_c('p',{staticClass:"text-h5 text--primary"},[_vm._v(" Pregunta "+_vm._s(_vm.currentQuestionIndex + 1)+"/"+_vm._s(_vm.exam.questions ? _vm.exam.questions.length : 0)+" ")]),_c('p',[_vm._v("Respuestas correctas requeridas: "+_vm._s(_vm.requiredAssertions))]),_c('div',[_vm._v("Tiempo restante")]),_c('v-row',{staticClass:"mx-0"},[_c('vue-countdown-timer',{attrs:{"start-time":_vm.startAt,"end-time":_vm.endAt,"interval":1000,"start-label":'',"end-label":'',"label-position":"begin","end-text":'Examen finalizado',"day-txt":'días',"hour-txt":'horas',"minutes-txt":'minutos',"seconds-txt":'segundos',"show-zero":false},on:{"start_callback":function($event){''},"end_callback":function($event){return _vm.saveUserAnswers()}}})],1)],1),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"green darken-1","dark":"","block":""},on:{"click":function($event){$event.preventDefault();return _vm.saveUserAnswers()}}},'v-btn',attrs,false),on),[_vm._v(" Finalizar ")])]}}])},[_c('span',[_vm._v("Enviar respuestas")])]),_c('v-dialog',{attrs:{"width":_vm.dialogWidth},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.aproved)?_c('v-card-title',{staticClass:"text-subtitle-1 text-center grey lighten-2 justify-center",staticStyle:{"word-break":"break-word"},attrs:{"primary-title":""}},[_vm._v(" ¡Felicidades! "),_c('br'),_vm._v(" Acabas de aprobar el examen de "+_vm._s(_vm.exam.course.name)+" ")]):_c('v-card-title',{staticClass:"text-body-1 grey lighten-2 mx-auto justify-center",attrs:{"primary-title":""}},[_vm._v(" ¡No te rindas! "),_c('br'),_vm._v(" No cumpliste con el requisito de "+_vm._s(_vm.requiredAssertions)+" respuestas correctas. Inténtalo nuevamente :)' ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.userAnswers,"item-key":"id","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.data.answer",fn:function(ref){
var item = ref.item;
return [_c('span',{class:[
                        item.data.answer.valid
                          ? 'response__border--aproved-color'
                          : 'response__border--failed-color',
                        'response-cell' ]},[_vm._v(" "+_vm._s(item.data.answer.text)+" ")])]}}],null,true)}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$router.push({ name: 'business/elearning/UserCourses' })}}},[_vm._v(" Aceptar ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }